import { admin2 } from '@/api/instance'

// => 建立付款方式
export const CreatePayment = async ({
  shopId,
  type,
  displayName,
  enable,
  jkosStoreId,
  jkosApiKey,
  jkosSercertKey,
  newebpayMerchantId,
  newebpayHashKey,
  newebpayHashIV,
  // newebpayPayGateWay,
  linePayChannelId,
  linePayChannelSecretKey,
  pcHomePayAppID,
  pcHomePaySecret,
  atmBankCode,
  atmBankName,
  atmBranchName,
  atmAccountName,
  atmAccountNumber,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/ecommercePaymentConfig`,
    data: {
      type,
      displayName,
      enable,
      jkosStoreId,
      jkosApiKey,
      jkosSercertKey,
      newebpayMerchantId,
      newebpayHashKey,
      newebpayHashIV,
      // newebpayPayGateWay,
      linePayChannelId,
      linePayChannelSecretKey,
      pcHomePayAppID,
      pcHomePaySecret,
      atmBankCode,
      atmBankName,
      atmBranchName,
      atmAccountName,
      atmAccountNumber,
    },
  })
  return res
}

// => 取得付款方式
export const GetPayment = async ({ shopId, start, limit, enable, name }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommercePaymentConfig`,
    params: {
      start,
      limit,
      enable,
      name,
    },
  })
  return res
}

// => 取得付款方式
export const GetPaymentCount = async ({ shopId, name }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommercePaymentConfig/count`,
    params: { name },
  })
  return res
}

// => 取得付款方式
export const FindPayment = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/ecommercePaymentConfig/${id}`,
  })
  return res
}

// => 更新付款方式
export const UpdatePayment = async ({
  shopId,
  id,
  type,
  enable,
  jkosStoreId,
  jkosApiKey,
  jkosSercertKey,
  newebpayMerchantId,
  newebpayHashKey,
  newebpayHashIV,
  linePayChannelId,
  linePayChannelSecretKey,
  pcHomePayAppID,
  pcHomePaySecret,
  displayName,
  // newebpayPayGateWay,
  atmBankCode,
  atmBankName,
  atmBranchName,
  atmAccountName,
  atmAccountNumber,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/ecommercePaymentConfig/${id}`,
    data: {
      type,
      displayName,
      enable,
      jkosStoreId,
      jkosApiKey,
      jkosSercertKey,
      newebpayMerchantId,
      newebpayHashKey,
      newebpayHashIV,
      linePayChannelId,
      linePayChannelSecretKey,
      pcHomePayAppID,
      pcHomePaySecret,
      // newebpayPayGateWay,
      atmBankCode,
      atmBankName,
      atmBranchName,
      atmAccountName,
      atmAccountNumber,
    },
  })
  return res
}

// => 刪除付款方式
export const DeletePayment = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'delete',
    url: `/${shopId}/ecommercePaymentConfig/${id}`,
  })
  return res
}
